:root {
  --white: #ffffff;
  --black: #000000;

  --primary: #0164C1;
  --primaryLight: #dde7f1;

  --secondary: #1DB954;

  --warn: #FE9820;
  --danger: #EC1D1D;
  --error: #FF3B30;
  --success: #28c879;

  --subText: #7b7b7b;

  --gray: #727272;
  --grayBg: #EDEDED;

  --mat-option-label-text-font: "Poppins", san-serif;
  --mat-select-trigger-text-size: 15px;
}


$primary: #0164C1;
$primary-light: rgba($primary, 0.5);
$secondary: #1DB954;
$input-border: #a5a5a5;
$border-color: #cedceb;
// $red: #ce0101;
$green: #4aaf39;
$success: #0d8c69;
$info: #25a9e0;
$warning: #ffc107;
$warn: #f44336;
$error: #ce0101;
$white: #ffffff;
$black: rgba(0, 0, 0, 1);
$gray-light: #f0f0f0;
$gray: rgba(216, 216, 216, 1);
// $grey: #8a8a8a;
$grey: rgb(165 165 165);
$darkGray: #585858;
$pink: #ffecec;
$textColor: #333333;
$background: rgba(238, 237, 241, 1);