.popup-ui {
    padding: 30px;
    border-radius: 20px;
    background: #FFF;
    overflow: auto;
    max-height: 90vh;
    max-width: 100%;

    &.extra-large {
        width: 800px;
    }
    &.large {
        width: 650px;
    }
    &.medium {
        width: 500px;
    }
    &.small{
        width: 480px;
    }

    .popup-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &.js-center{
            justify-content: center;
        }

        .close-icon-button {
            padding: 0px !important;
            background: transparent !important;
            width: 48px;
            height: 48px;
            border: none;
            cursor: pointer;

            img {
                display: table-cell;
                width: 100%;
                height: 100%;
            }
        }
    }

    .popup-body {
        padding-top: 20px;
    }
}


.cdk-overlay-pane {
    &.large-dialog {
        width: 500px;
    }

    .mat-mdc-dialog-container {
        max-height: 90vh;
        overflow: auto;
        .mat-mdc-dialog-surface{
            overflow: hidden;
        }
    }
    &.sub-event-detail-dilaog{
        width: 900px;
        .img-cover{
            width: 100%;
            border-radius: 10px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
