@import './typography';
@import './common';
@import './reset';
@import './buttons';
@import './margins';
@import './variables';
@import './dialog';
@import './grid';
.custome-class {
    .timepicker__header {
    background-color: var(--primary) !important;
    }
    .timepicker__main-content {
        .timepicker__body {
            ngx-material-timepicker-12-hours-face{
                ngx-material-timepicker-face{
                    .clock-face{
                        .clock-face__container{
                            .active{
    background-color: var(--primary) !important;

                            }
                        }
                    }
                }
            }
        }
    }
    --clock-hand-color: var(--primary);
    .timepicker__actions{
        ngx-material-timepicker-button{
            .timepicker-button{
                color: var(--primary) !important;
            }
        }
    }
  }