button{
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}
.btn {
  padding: 16px 20px;
  font-size: 14px;
  border-radius: 10px;
  outline: none;
  height: 54px;
  line-height: 1.5;
  transition: 0.3s;
  background: var(--primaryLight);
  color: var(--primary);
  border: 1px solid var(--primaryLight);
  font-weight: 500;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  &.btn-download {
    color: var(--white);
    background: var(--primary);
    border-color: var(--primary);
    height: 42px;
  }

  &.btn-primary {
    color: var(--white);
    background: var(--primary);
    border-color: var(--primary);
  }
  &.btn-primary-border {
    color: var(--primary);
    background: var(--white);
    border-color: var(--primary);
  }
  &.btn-secondary {
    color: var(--white);
    background: var(--secondary);
    border-color: var(--secondary);
  }
  &.btn-secondaryLight {
    color: var(--secondary);
    background: #e0f1e6;
    border-color: #e0f1e6;
  }
  &.btn-secondary-border {
    color: var(--secondary);
    background: var(--white);
    border-color: var(--secondary);
  }

  &.btn-gray {
    color: var(--gray);
    border-color: var(--grayBg);
    background-color: var(--grayBg);
  }

  &.btn-black-active {
    color: var(--primary);
    border-color: #2c2d2f;
    background-color: #202020;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.btn-draft {
    background-color: rgba(29, 185, 84, 0.07);
    color: #1db954;
    border-color: #1db954;
  }

  &.btn-black-cancel {
    color: var(--white);
    border-color: #303030;
    background-color: #191919;
  }
  &.btn-gray-cancel {
    background-color: #dfdfdf;
    color: #7a7a7a;
    border-color: #dfdfdf;
  }
   &.btn-white {
    background-color: var(--white);
    color: var(--gray);
    border-color: var(--grayBg);
  }

  &.btn-mini-fab {
    border-radius: 50%;
    padding: 10px 10px;
    font-size: 14px;
    height: auto;
  }

  &.btn-delete {
    color: var(--danger);
    border-color: rgb(255 230 230);
    background-color:  rgb(255 230 230);
  }
  &.btnAdd{
    width: 90px;
    height: 30px;
    font-size: 14.25px;
    padding: 5px;
    border-radius: 4px;
    background: var(--grayBg);
    color: var(--gray);
  }
  &.btnRemove{
    width: 90px;
    height: 30px;
    font-size: 14.25px;
    padding: 5px;
    border-radius: 4px;
    background: var(--white);
    color: var(--error);
  }

  &.mw-100{
    min-width: 190px;
  }

  &.large {
    padding: 17.5px 20px;
    font-size: 16px;
    height: 60px;
  }

  .icon-right {
    padding-left: 4px;
  }

  &::before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: -1;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 100%
    );
    transform: skewX(-25deg);
  }

  &:hover {
    &::before {
      z-index: 1;
      animation: imageH 1s;
      -webkit-animation: imageH 1s;
      -moz-animation: imageH 1s;
      -ms-animation: imageH 1s;
      -o-animation: imageH 1s;
    }
  }

  &:disabled {
    &:hover {
      &::before {
        display: none;
      }
    }
  }
  &.filterApplied {
    position: relative;
    overflow: initial;
    &::before{
      display: none;
    }
    &::after {
        height: 10px;
        right: -4px;
        top: -4px;
        width: 10px;
        background: var(--danger);
        position: absolute;
        border-radius: 50%;
        content: '';
        display: block;
    }
}
}

@-webkit-keyframes imageH {
  100% {
    left: 125%;
  }
}

@keyframes imageH {
  100% {
    left: 125%;
  }
}

.btnText{
  color: #1DB954;
  font-size: 14px;
  background: transparent;
  border: none;
  padding: 0;
  font-weight: 400;
}