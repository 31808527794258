h1,
h2,
h3,
h4,
h5,
h6,
p,
label {
  margin: 0;
  line-height: 1.4;
  font-family: "Poppins", sans-serif !important;
}

label {
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

h2 {
  font-size: 32px;
}
h2.large {
  font-size: 36px;
}
h2.ex-large {
  font-size: 42px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}
h5.large {
  font-size: 22px;
}

h6 {
  font-size: 18px;
}
h6.small {
  font-size: 16px;
}
h6.ex-small {
  font-size: 14px;
}

p {
  font-size: 16px;
}
p.ex-small {
  font-size: 12px;
}
p.small {
  font-size: 14px;
}
p.large {
  font-size: 18px;
}
p.ex-large {
  font-size: 20px;
}

.fs-15 {
  font-size: 15px !important;
}

@media screen and (max-width: 768px) {
  h2 {
    font-size: 28px;
  }
  h2.large {
    font-size: 22px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 18px;
  }
  h5.large {
    font-size: 20px;
  }
  h6 {
    font-size: 16px;
  }
  h6.small {
    font-size: 14px;
  }
  h6.ex-small {
    font-size: 13px;
  }
  p {
    font-size: 15px;
  }
  p.large {
    font-size: 16px;
  }
  .fs-15 {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 576px) {
  h2 {
    font-size: 22px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 18px;
  }
  h5 {
    font-size: 15px;
  }
  h6.ex-small {
    font-size: 12px;
  }
  p {
    font-size: 14px;
  }
  p.ex-small {
    font-size: 10px;
  }
  p.small {
    font-size: 12px;
  }
  p.large {
    font-size: 14px;
  }
  .fs-15 {
    font-size: 13px !important;
  }
}
.loader-cover {
  width: 100vw;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  height: 100vh;
  z-index: 99;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-cover lottie-player {
  height: 350px;
}

.color-red {
  color: red;
}

.MuiFormControl-root,
.MuiFormControl-root .MuiTypography-root,
.MuiInputBase-input,
.otp-field__input,
.filter-cover,
.mat-expansion-panel-header,
.mdc-form-field,
.mat-mdc-paginator-container,
.mat-mdc-form-field {
  font-family: "Poppins", sans-serif !important;
}

.delete-button-for-header-cover .delete-button-for-header {
  box-shadow: none !important;
}
.delete-button-for-header-cover .delete-button-for-header img {
  display: table-cell;
}

.MuiLink-root {
  cursor: pointer;
  text-decoration: inherit !important;
}

.container {
  max-width: 1300px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

img {
  max-width: 100%;
}

figure {
  margin: 0;
  line-height: 0.6;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.MuiLinearProgress-bar {
  background-color: var(--primary) !important;
}

.MuiTooltip-tooltip {
  background-color: var(--white) !important;
  color: var(--subText) !important;
  box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.1607843137);
  padding: 12px 16px !important;
  line-height: 15px !important;
}
@media screen and (max-width: 576px) {
  .MuiTooltip-tooltip {
    font-size: 11px !important;
  }
}
.MuiTooltip-tooltip .MuiTooltip-arrow {
  color: var(--white) !important;
}

.MuiDialog-container .MuiPaper-root {
  margin: 32px 16px;
  width: calc(100% - 32px);
  width: calc(100% - 32px);
}

.MuiSnackbar-root {
  top: 50px;
  bottom: auto !important;
  left: 50% !important;
  right: auto !important;
  transform: translateX(-50%);
  width: max-content;
  max-width: 750px;
}
@media screen and (max-width: 768px) {
  .MuiSnackbar-root {
    max-width: calc(100% - 60px);
  }
}
@media screen and (max-width: 576px) {
  .MuiSnackbar-root {
    max-width: calc(100% - 32px);
  }
}
.MuiSnackbar-root .MuiPaper-root {
  padding: 6px 48px;
  border-radius: 0px;
  font-family: "Monument", sans-serif;
  text-align: center;
  justify-content: center;
}
@media screen and (max-width: 992px) {
  .MuiSnackbar-root .MuiPaper-root {
    padding: 4px 24px;
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .MuiSnackbar-root .MuiPaper-root {
    padding: 3px 20px;
    font-size: 13px;
  }
}
@media screen and (max-width: 576px) {
  .MuiSnackbar-root .MuiPaper-root {
    padding: 2px 16px;
    font-size: 12px;
  }
}
.MuiSnackbar-root.success .MuiPaper-root {
  background: var(--success);
}
.MuiSnackbar-root.danger .MuiPaper-root {
  background: var(--danger);
}

.MuiMenu-list {
  max-height: 300px;
}

.MuiCheckbox-root {
  min-width: 38px;
  margin-top: -9px !important;
  margin-left: -9px !important;
  padding: 9px !important;
  border-radius: 50% !important;
}

@media screen and (max-width: 576px) {
  .MuiCheckbox-root {
    margin-top: -11px !important;
  }
  .MuiFormControlLabel-root {
    margin-right: 0 !important;
  }
}
.custom-slider {
  position: relative;
  display: inline-block;
  width: 43px;
  height: 24px;
}
.custom-slider input {
  opacity: 0;
  width: 0;
  height: 0;
}
.custom-slider .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 34px;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.custom-slider .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 5px;
  bottom: 4px;
  border-radius: 50%;
  background-color: var(--white);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.custom-slider input:checked + .slider {
  background-color: #2196f3;
}
.custom-slider input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
.custom-slider input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

.close-btn-icon {
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
}

.MuiDialog-root {
  z-index: 9999 !important;
}

.form-field-cover label {
  display: inline-flex;
  font-size: 16px;
  margin-bottom: 9px;
  line-height: 1.5;
  font-weight: 500;
  color: var(--black);
}
.form-field-cover .mat-mdc-form-field {
  width: 100%;
}
.form-field-cover .mat-mdc-form-field .mdc-text-field {
  background-color: #fbfbfb;
  border-radius: 9px;
  padding-left: 21.5px;
  padding-right: 21.5px;
}
.form-field-cover .mat-mdc-form-field .mdc-text-field .mat-mdc-form-field-infix {
  padding-top: 15px;
  padding-bottom: 15px;
  min-height: 55px;
}
.form-field-cover .mat-mdc-form-field .mdc-text-field input,
.form-field-cover .mat-mdc-form-field .mdc-text-field textarea {
  font-size: 15px;
  letter-spacing: 0;
}
.form-field-cover .mat-mdc-form-field .mdc-text-field input::placeholder,
.form-field-cover .mat-mdc-form-field .mdc-text-field textarea::placeholder {
  color: #7b7b7b;
}
.form-field-cover .mat-mdc-form-field .mdc-text-field .mdc-notched-outline div {
  border-color: #e2e2e2;
}
.form-field-cover .mat-mdc-form-field .mdc-text-field .mdc-notched-outline div.mdc-notched-outline__leading {
  border-radius: 10px 0 0 10px;
}
.form-field-cover .mat-mdc-form-field .mdc-text-field .mdc-notched-outline div.mdc-notched-outline__trailing {
  border-radius: 0 10px 10px 0;
}
.form-field-cover .mat-mdc-form-field .mat-mdc-form-field-icon-suffix {
  margin-right: -10px;
}
.form-field-cover.search-bar {
  width: 370px;
}
.form-field-cover.search-bar .mat-mdc-form-field .mdc-text-field {
  background-color: #fff;
  padding-left: 20px;
  padding-right: 20px;
}
.form-field-cover.search-bar .mat-mdc-form-field .mdc-text-field .mat-mdc-form-field-infix {
  padding-top: 15px;
  padding-bottom: 15px;
  min-height: 55px;
}
.form-field-cover.search-bar .mat-mdc-form-field .mdc-text-field input {
  font-size: 15px;
}
.form-field-cover.search-bar .mat-mdc-form-field .mdc-text-field input::-webkit-search-cancel-button {
  cursor: pointer;
}
.form-field-cover.search-bar .mat-mdc-form-field .mdc-text-field input::placeholder {
  color: #767676;
}
.form-field-cover.search-bar .mat-mdc-form-field .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mat-mdc-select {
  font-family: "Poppins", sans-serif !important;
}
.mat-mdc-select .mat-mdc-select-placeholder {
  color: #8f8996;
  font-size: 15px;
}

.mdc-label {
  color: var(--black);
  font-weight: 400;
  font-size: 16px;
}

.mdc-checkbox {
  margin-left: -11px !important;
}
.mdc-checkbox .mdc-checkbox__ripple {
  background-color: #d3d3d3 !important;
}
.mdc-checkbox .mat-mdc-focus-indicator {
  display: none;
}

.mdc-radio {
  margin-left: -10px !important;
}

.mat-mdc-checkbox .mat-mdc-checkbox-touch-target {
  width: 100%;
  height: 100%;
}
.mat-mdc-checkbox .mdc-checkbox__background {
  color: #8e8e8e;
  border-width: 1px;
  background-color: transparent !important;
  border-color: revert !important;
}
.mat-mdc-checkbox .mdc-checkbox__background svg {
  color: transparent !important;
}
.mat-mdc-checkbox .mdc-checkbox__background .mdc-checkbox__checkmark-path {
  stroke-width: 2px;
}
.mat-mdc-checkbox.mat-mdc-checkbox-checked .mdc-checkbox__background {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}
.mat-mdc-checkbox.mat-mdc-checkbox-checked .mdc-checkbox__background .mdc-checkbox__checkmark-path {
  color: var(--white) !important;
}
.mat-mdc-checkbox.mdc-checkbox--disabled {
  opacity: 0.5;
}

.profile-menu {
  width: 256px;
  padding: 7px;
}
.profile-menu .mat-mdc-menu-item .mat-mdc-menu-item-text {
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
}
.profile-menu .mat-mdc-menu-item:not(:last-child) {
  border-bottom: 1px solid #dedede;
}

.mat-mdc-form-field-subscript-wrapper {
  display: grid;
}
.mat-mdc-form-field-subscript-wrapper::before {
  display: none !important;
}
.mat-mdc-form-field-subscript-wrapper .mat-mdc-form-field-error-wrapper,
.mat-mdc-form-field-subscript-wrapper .mat-mdc-form-field-hint-wrapper {
  padding: 0 !important;
  position: inherit;
}
.mat-mdc-form-field-subscript-wrapper mat-error {
  font-size: 14px;
  color: var(--error);
  font-weight: 500;
  text-align: right;
  padding-top: 7px;
  line-height: 18px;
  font-family: "Poppins", sans-serif !important;
}
.mat-mdc-form-field-subscript-wrapper mat-error::first-letter {
  text-transform: uppercase !important;
}
.mat-mdc-form-field-subscript-wrapper mat-hint {
  font-size: 14px;
  color: var(--subText);
  text-align: right;
  padding-top: 7px;
  line-height: 18px;
  font-family: "Poppins", sans-serif !important;
}
.mat-mdc-form-field-subscript-wrapper mat-hint::first-letter {
  text-transform: uppercase !important;
}

.click-here {
  margin-top: 8px;
  transition: 0.2s;
  position: relative;
}
.click-here span {
  color: #1db954;
  cursor: pointer;
  font-weight: 500;
}

.upload-img-cover {
  width: 100%;
  aspect-ratio: 2/1;
  border-radius: 10px;
  margin: auto;
}
.upload-img-cover .upload-btn {
  width: 100%;
  height: 100%;
  background: #f2f7fd;
  border-radius: 10px;
  padding: 0;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.upload-img-cover .upload-btn .mdc-button__label {
  min-width: 100% !important;
  min-height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.upload-img-cover .upload-btn input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.upload-img-cover .upload-btn img {
  display: table-cell;
}
.upload-img-cover .img-cover {
  background: #f7f7f7;
  border-radius: 10px;
}
.upload-img-cover.circular {
  border-radius: 50%;
  aspect-ratio: 1/1;
  width: 220px;
  margin-left: 0;
}
.upload-img-cover.circular .img-cover {
  border-radius: 50%;
}
.upload-img-cover.circular .upload-btn {
  border-radius: 50%;
}
.upload-img-cover.circular .remove-cover {
  right: 20px !important;
  top: 20px !important;
}

.bg-white {
  border-radius: 13px;
  background: #fff;
  box-shadow: 0px 4px 8px 8px rgba(0, 0, 0, 0.01);
  padding: 32px 22px;
}

.bg-gray {
  border-radius: 13px;
  background: #f6f6f6;
  box-shadow: 0px 4px 8px 8px rgba(0, 0, 0, 0.01);
  padding: 22px;
}

.status-cover {
  font-size: 14px;
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 20px;
  white-space: nowrap;
}
.status-cover.success {
  background: #e8f9ee;
  color: var(--secondary);
}
.status-cover.upcoming {
  background: #e8f1f9;
  color: #0164c1;
}
.status-cover.danger {
  background-color: #ffe3e3;
  color: var(--danger);
}
.status-cover.pending {
  color: #ff7c00;
  background-color: #fff2e5;
}

.detail-cover label {
  display: inline-block;
  color: var(--subText);
  margin-bottom: 8px;
  font-size: 15px;
}
.detail-cover .value {
  font-size: 15px;
  font-weight: 500;
  word-break: break-all;
}

.mat-mdc-tab-links .mdc-tab {
  width: 250px;
  flex-grow: initial !important;
}
.mat-mdc-tab-links .mdc-tab .mdc-tab__text-label {
  font-size: 16px;
}
.mat-mdc-tab-links .mdc-tab .mdc-tab-indicator .mdc-tab-indicator__content {
  border-color: #ececec;
  opacity: 1;
}
.mat-mdc-tab-links .mdc-tab.active-link .mdc-tab__text-label {
  color: black !important;
  font-weight: 600;
}
.mat-mdc-tab-links .mdc-tab.active-link .mdc-tab-indicator__content {
  border-color: var(--primary) !important;
}

.profile-image-cover {
  width: 165px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  overflow: hidden;
  max-width: 70%;
  border: 1px solid #e2e2e2;
  margin: auto;
}
.profile-image-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before {
  background-color: var(--primary) !important;
}
.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background div {
  border-color: var(--primary) !important;
}

.mat-mdc-radio-group .mat-mdc-radio-button .mdc-form-field {
  width: 100%;
  display: flex;
  align-items: center;
  letter-spacing: 0;
}
.mat-mdc-radio-group .mat-mdc-radio-button .mdc-form-field label {
  font-weight: 500;
  margin-bottom: 0;
  color: black;
  letter-spacing: 0;
}
.mat-mdc-radio-group.organizer-radio-buttons .mat-mdc-radio-button .mdc-form-field {
  flex-direction: row !important;
  border-bottom: 1px solid #d9d9d9;
  padding: 10px 0px;
}
.mat-mdc-radio-group.organizer-radio-buttons .mat-mdc-radio-button .mdc-form-field .mdc-label {
  width: 100%;
}
.mat-mdc-radio-group.organizer-radio-buttons .mat-mdc-radio-button .mdc-form-field .mdc-label span {
  font-weight: 400;
  color: gray;
}

.cdk-overlay-pane .mat-mdc-menu-panel {
  max-width: 300px;
}
.cdk-overlay-pane .mat-mdc-menu-panel .mat-mdc-menu-content {
  overflow: auto;
}

.slideImage {
  display: none;
}

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.mat-mdc-menu-panel {
  border-radius: 10px !important;
}

.cc-dropdown-cover button {
  background: transparent;
  border: none;
  padding: 12px 7px 12px 14px;
  position: relative;
  margin-right: 10px;
}
.cc-dropdown-cover button::after {
  content: "";
  position: absolute;
  right: 0;
  top: 8px;
  bottom: 8px;
  width: 1px;
  background: #e1e1e1;
}
.cc-dropdown-cover .mat-mdc-text-field-wrapper {
  padding-left: 0 !important;
}

.bordered-card {
  border-radius: 13px;
  background: #fff;
  border: 1px solid #d3d3d3;
  padding: 15px;
}
.bordered-card.link-added {
  background: var(--primary);
}
.bordered-card.padding-30 {
  padding: 30px;
}
.bordered-card.inPreview {
  padding: 32px 22px;
  box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.01);
}
.bordered-card.border-2px {
  border: 2px solid var(--black);
}

.light-blue-card {
  border-radius: 14px;
  background: #f2f7fd;
  border: 1px solid #a9cbea;
  padding: 13px 9px 15px;
}
.light-blue-card .bordered-card {
  padding: 13px 8px;
}

.mat-mdc-select-panel input.filter {
  width: calc(100% - 40px);
  margin: 10px 20px;
  padding: 12px 15px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #a6a6a6;
  font-family: "Poppins", sans-serif;
}

.mat-mdc-menu-item:not([disabled]):hover {
  background-color: #eaeaea;
}

.page-create-event .mat-mdc-form-field-has-icon-prefix .mat-mdc-text-field-wrapper {
  padding-left: 0 !important;
}
.page-create-event .mat-stepper-horizontal.StepperForClassPreview .mat-horizontal-content-container {
  padding: 0 0 30px !important;
  border-radius: 13px;
}
.page-create-event .mat-stepper-horizontal.StepperForClassPreview .mat-horizontal-content-container .page-event-detail .singlePreviewWithBorder {
  padding: 30px;
  border-radius: 13px;
  border: 1px solid var(--black);
}
.page-create-event .mat-stepper-horizontal.StepperForClassPreview .mat-horizontal-content-container .page-event-detail .singlePreviewWithBorder:not(:last-child) {
  margin-bottom: 60px;
}
.page-create-event .mat-stepper-horizontal.StepperForSubEvent .mat-horizontal-content-container {
  padding: 0 0 30px !important;
  border-radius: 13px;
}
.page-create-event .mat-stepper-horizontal.StepperForSubEvent .mat-horizontal-content-container .page-event-detail .singlePreviewWithBorder {
  padding: 30px;
  border-radius: 13px;
  border: 1px solid var(--black);
}
.page-create-event .mat-stepper-horizontal.StepperForSubEvent .mat-horizontal-content-container .page-event-detail .singlePreviewWithBorder:not(:last-child) {
  margin-bottom: 60px;
}

.form-for-inc-dec .mat-mdc-form-field-icon-suffix {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin-right: -12px;
}
.form-for-inc-dec .counter-btn {
  background: #e7e7e7;
  box-shadow: none;
  padding: 0;
  width: 27px;
  height: 22px;
  overflow: hidden;
}
.form-for-inc-dec .counter-btn span {
  border-radius: 0 !important;
}
.form-for-inc-dec .counter-btn .mat-icon {
  color: black !important;
}
.form-for-inc-dec .counter-btn.btn-top {
  border-radius: 6px 6px 0 0;
}
.form-for-inc-dec .counter-btn.btn-down {
  border-radius: 0 0 6px 6px;
}

.form-for-hour {
  position: relative;
}
.form-for-hour .hour-text {
  position: absolute;
  left: 16px;
}

.ticket-accordion > form > .mat-expansion-panel {
  box-shadow: none !important;
  border: 1px solid #e2e2e2;
  border-radius: 8px !important;
}
.ticket-accordion > form > .mat-expansion-panel > .mat-expansion-panel-header {
  height: auto !important;
  padding: 20px 16px;
  height: 78px !important;
}
.ticket-accordion > form > .mat-expansion-panel > .mat-expansion-panel-header:hover {
  background: var(--white) !important;
}
.ticket-accordion > form > .mat-expansion-panel > .mat-expansion-panel-header .mat-expansion-indicator {
  display: none;
}
.ticket-accordion > form > .mat-expansion-panel > .mat-expansion-panel-header .mat-content {
  align-items: center;
  padding-right: 20px;
  position: relative;
}
.ticket-accordion > form > .mat-expansion-panel > .mat-expansion-panel-header .mat-content .mat-icon.chevron {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}
.ticket-accordion > form > .mat-expansion-panel > .mat-expansion-panel-header.mat-expanded .mat-content .mat-icon.chevron {
  transform: translateY(-50%) rotate(180deg);
}
.ticket-accordion > form > .mat-expansion-panel > .mat-expansion-panel-header.mat-expanded + .mat-expansion-panel-content {
  position: relative;
}
.ticket-accordion > form > .mat-expansion-panel > .mat-expansion-panel-header.mat-expanded + .mat-expansion-panel-content::before {
  content: "";
  position: absolute;
  left: 8px;
  right: 8px;
  width: calc(100% - 16px);
  height: 1px;
  background: #e2e2e2;
}
.ticket-accordion > form > .mat-expansion-panel > .mat-expansion-panel-content > .mat-expansion-panel-body {
  padding: 16px 16px 30px;
}
.ticket-accordion.for-subEvent > form > .mat-expansion-panel {
  border-radius: 0 !important;
  border: none;
}
.ticket-accordion.for-subEvent > form > .mat-expansion-panel > .mat-expansion-panel-header {
  padding: 20px 15px;
  border-bottom: 1px solid #e2e2e2 !important;
  border-radius: 0 !important;
}
.ticket-accordion.for-subEvent > form > .mat-expansion-panel > .mat-expansion-panel-content {
  position: relative;
}
.ticket-accordion.for-subEvent > form > .mat-expansion-panel > .mat-expansion-panel-content::before {
  display: none !important;
}
.ticket-accordion.for-subEvent > form > .mat-expansion-panel > .mat-expansion-panel-content > .mat-expansion-panel-body {
  padding: 42px 55px;
}
.ticket-accordion.for-subEvent > form > .mat-expansion-panel.mat-expanded {
  border: 2px solid var(--primary);
  margin-bottom: 20px;
}
.ticket-accordion.for-subEvent > form > .mat-expansion-panel.mat-expanded > .mat-expansion-panel-header {
  border-bottom-color: var(--primary) !important;
  background: #dae8f8 !important;
}
.ticket-accordion.for-subEvent:first-child .mat-expansion-panel {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.new-acc-ui {
  padding: 0;
  box-shadow: none !important;
  border-radius: 10px !important;
}
.new-acc-ui .mat-expansion-panel-header {
  border: 1px solid #a6c9ea;
  background: #edf4fb !important;
  height: auto !important;
  padding: 16px 20px;
}
.new-acc-ui .mat-expansion-panel-header .mat-content p {
  color: var(--primary);
}
.new-acc-ui .mat-expansion-panel-header .chevron {
  color: var(--primary);
  margin-right: -6px;
}
.new-acc-ui .mat-expansion-panel-header .mat-expansion-indicator {
  display: none;
}
.new-acc-ui .mat-expansion-panel-header.mat-expanded {
  background: var(--primary) !important;
  height: auto;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.new-acc-ui .mat-expansion-panel-header.mat-expanded .mat-content p {
  color: var(--white);
}
.new-acc-ui .mat-expansion-panel-header.mat-expanded .chevron {
  color: var(--white);
  transform: rotate(180deg);
}
.new-acc-ui .mat-expansion-panel-content {
  background: #edf4fb !important;
}
.new-acc-ui .mat-expansion-panel-content .mat-expansion-panel-body {
  padding: 25px 20px 22px;
}

.has-badge {
  position: relative;
}
.has-badge .badge-list {
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.has-badge .badge-list li {
  font-size: 13px;
  border-radius: 20px;
  line-height: 1;
  padding: 7.5px 10px;
  background: rgb(36, 36, 36);
  color: white;
}
.has-badge .badge-list li.green {
  background: #1db954;
}
.has-badge .badge-list li.blue {
  background: #0164c1;
}
.has-badge .badge-list li:not(:last-child) {
  margin-right: 5px;
}

.btn-cover {
  margin-left: auto;
  width: 185px;
}

.mat-sort-header-arrow {
  opacity: 0.5 !important;
  transform: none !important;
}

.has-info-text {
  padding: 8px 16px 24px 16px !important;
  min-height: auto !important;
}
.has-info-text + p {
  margin-top: -24px;
  margin-bottom: 6px;
  font-size: 14px;
  padding: 0 16px;
  color: var(--subText);
}

.mat-datepicker-toggle button {
  width: 40px !important;
  height: 40px !important;
  padding: 6px !important;
  margin-left: 3px !important;
}

hr {
  border: none;
  height: 1px;
  background: #d6d6d6;
}

.has-contry-dropdown .mat-mdc-form-field-icon-prefix {
  display: flex;
  padding: 18px;
  margin: -12px 10px -12px -12px;
  border-right: 1px solid #d3d3d3;
}
.has-contry-dropdown .country-dropdown {
  border: none;
  margin: -10px;
  width: fit-content;
  cursor: pointer;
}
.has-contry-dropdown .country-dropdown img {
  width: 40px;
}

.phone-no-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e2e2e2;
  border-radius: 9px;
  padding-left: 10px;
  position: relative;
}
.phone-no-wrap .mdc-notched-outline div {
  border: none;
  border-radius: 0;
}
.phone-no-wrap .country-code {
  width: 62px;
  position: relative;
  border-right: 1px solid #d3d3d3;
}
.phone-no-wrap .country-code .mat-mdc-form-field {
  width: 100%;
  pointer-events: none;
}
.phone-no-wrap .country-code .mat-mdc-form-field-infix {
  padding: 9px 0;
  height: 44px;
  min-height: 44px;
}
.phone-no-wrap .country-code .mat-mdc-text-field-wrapper {
  padding-left: 10px;
}
.phone-no-wrap .country-code .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.phone-no-wrap .country-code .mat-mdc-select-arrow-wrapper {
  display: none;
}
.phone-no-wrap .country-code .country-code-flag img {
  width: 30px;
  border-radius: 4px;
  height: 26px;
  object-fit: cover;
}
.phone-no-wrap .input-wrap {
  width: calc(100% - 62px);
  position: relative;
}
.phone-no-wrap .input-wrap .mat-mdc-form-field {
  width: 100%;
}
.phone-no-wrap .input-wrap .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.description-detail .ql-editor {
  padding: 0;
}
.description-detail .btn-read-more {
  border: none;
  background: transparent;
  margin-left: auto;
  color: var(--primary);
  font-weight: 500;
}

.ql-editor::before {
  font-style: inherit !important;
  font-size: "Poppins", san-serif !important;
  font-size: 15px !important;
}

.date-cover .mat-mdc-text-field-wrapper,
app-time .mat-mdc-text-field-wrapper {
  position: relative;
  padding-right: 0 !important;
}
.date-cover .mat-mdc-text-field-wrapper input,
app-time .mat-mdc-text-field-wrapper input {
  position: absolute;
  top: 0;
  height: 100% !important;
}

.organization-details .mat-stepper-horizontal {
  background: transparent;
}
.organization-details .mat-stepper-horizontal .mat-horizontal-stepper-header-container {
  overflow: hidden;
  margin-bottom: 25px;
  margin-top: 12px;
  justify-content: flex-start;
  border-bottom: 5px solid #ececec;
}
.organization-details .mat-stepper-horizontal .mat-step-header {
  height: auto;
  padding: 18px 26px;
  background: transparent;
  border-bottom: 5px solid transparent;
}
.organization-details .mat-stepper-horizontal .mat-step-header:not(:last-child) {
  margin-right: 25px;
}
.organization-details .mat-stepper-horizontal .mat-step-header .mat-step-icon {
  display: none;
}
.organization-details .mat-stepper-horizontal .mat-step-header .mat-step-text-label {
  font-size: 18px;
  letter-spacing: 0;
  font-family: "Poppins", sans-serif !important;
}
.organization-details .mat-stepper-horizontal .mat-step-header[aria-selected=true] {
  border-bottom: 5px solid var(--primary);
  color: var(--primary);
}
.organization-details .mat-stepper-horizontal .mat-horizontal-content-container {
  padding: 40px;
  background: var(--white);
  box-shadow: 0px 4px 8px 8px rgba(0, 0, 0, 0.01);
  border-radius: 13px;
}
.organization-details .mat-stepper-horizontal .mat-stepper-horizontal-line {
  display: none;
}

.disabled {
  opacity: 0.7;
}

mat-form-field.mat-form-field-invalid .mat-mdc-text-field-wrapper {
  background: #ffe6e6 !important;
}
mat-form-field.mat-form-field-invalid .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mdc-notched-outline div {
  border-color: var(--error) !important;
}
mat-form-field.mat-form-field-invalid input::placeholder,
mat-form-field.mat-form-field-invalid .mat-mdc-select-placeholder,
mat-form-field.mat-form-field-invalid .mat-mdc-select-value {
  color: var(--error) !important;
}

mat-form-field.hide-errors .mat-mdc-text-field-wrapper {
  background: #fbfbfb !important;
}
mat-form-field.hide-errors .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mdc-notched-outline div {
  border-color: #e2e2e2 !important;
}
mat-form-field.hide-errors input::placeholder,
mat-form-field.hide-errors .mat-mdc-select-placeholder,
mat-form-field.hide-errors .mat-mdc-select-value {
  color: #7b7b7b !important;
}

quill-view .ql-editor {
  padding: 0;
}

.char-count {
  color: #8f8996;
  font-size: 14px;
  text-align: right;
  padding-bottom: 0;
}

.page-event-detail .img-cover {
  border-radius: 10px;
  aspect-ratio: 2/1;
  width: 100%;
}
.page-event-detail .img-cover img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  display: table-cell;
}

mat-slide-toggle .mdc-switch {
  width: 38px !important;
}
mat-slide-toggle .mdc-switch .mdc-switch__track {
  width: 38px !important;
  height: 20px !important;
  border-radius: 10px !important;
}
mat-slide-toggle .mdc-switch .mdc-switch__track::before, mat-slide-toggle .mdc-switch .mdc-switch__track::after {
  background: #ededed !important;
  border: none !important;
}
mat-slide-toggle .mdc-switch .mdc-switch__handle-track {
  width: 15px !important;
}
mat-slide-toggle .mdc-switch .mdc-switch__handle {
  width: 15px !important;
  height: 15px !important;
  left: 4px !important;
}
mat-slide-toggle .mdc-switch .mdc-switch__handle::after {
  background: #c7c7c7 !important;
}
mat-slide-toggle .mdc-switch .mdc-switch__handle .mdc-switch__ripple {
  width: 30px;
  height: 30px;
}
mat-slide-toggle .mdc-switch .mdc-switch__handle .mdc-switch__shadow,
mat-slide-toggle .mdc-switch .mdc-switch__handle .mdc-switch__icons {
  display: none;
}
mat-slide-toggle .mdc-switch:disabled .mdc-switch__track {
  opacity: 1 !important;
}
mat-slide-toggle .mdc-switch:disabled .mdc-switch__handle::after {
  opacity: 1 !important;
}
mat-slide-toggle .mdc-switch.mdc-switch--checked .mdc-switch__track::after {
  background: var(--primary) !important;
}
mat-slide-toggle .mdc-switch.mdc-switch--checked .mdc-switch__handle-track .mdc-switch__handle ::after {
  background: var(--white) !important;
}
mat-slide-toggle .mdc-switch.mdc-switch--checked:disabled .mdc-switch__track {
  opacity: 0.7 !important;
}
mat-slide-toggle .mdc-switch.mdc-switch--checked:disabled .mdc-switch__handle::after {
  opacity: 0.38 !important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
  background: var(--white) !important;
}

.ql-container {
  font-size: 15px;
  font-family: "Poppins", sans-serif;
}
.ql-container p {
  font-size: 15px;
}

.state-cover {
  font-size: 12px;
  line-height: 2;
  font-weight: 500;
  padding: 6px 16px;
  border-radius: 20px;
  display: inline-block;
  white-space: nowrap;
  color: var(--white);
}
.state-cover.published {
  background: var(--secondary);
}
.state-cover.error {
  background: var(--error);
}
.state-cover.draft {
  background: #0164c1;
}

.page-dashboard .counter-card {
  border-radius: 13px;
  background: var(--white);
  padding: 26px 20px;
  text-align: center;
  height: 100%;
  user-select: none;
}
.page-dashboard .counter-card p {
  color: #4a4a4a;
  margin-top: 12px;
  margin-bottom: 5px;
}

* {
  box-sizing: border-box;
  letter-spacing: 0 !important;
}

html,
body {
  height: 100%;
}

ul,
ol,
dl {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.visuallyHidden {
  position: fixed;
  overflow: hidden;
  opacity: 0;
  width: 0;
  height: 0;
}

body {
  font-family: "Poppins", sans-serif;
  padding: 0;
  margin: 0;
  overflow: hidden overlay;
}
@supports (-webkit-appearance: none) {
  body {
    overflow: overlay;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  background: #DDDBDB;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary);
}

::-webkit-scrollbar-corner {
  background-color: transparent;
  border-color: transparent;
}

a {
  text-decoration: none;
}

.light {
  font-weight: 300 !important;
}

.regular {
  font-weight: 400 !important;
}

.medium {
  font-weight: 500 !important;
}
.medium .mdc-label {
  font-weight: 500 !important;
}

.semi-bold {
  font-weight: 600 !important;
}

.bold {
  font-weight: 700 !important;
}

.extra-bold {
  font-weight: 800 !important;
}

.nowrap {
  white-space: nowrap;
}

.break-all {
  word-break: break-all;
}

.rounded {
  border-radius: 4px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize !important;
}

.left {
  text-align: left !important;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.inline-block {
  display: inline-block;
}

.pointer {
  cursor: pointer;
}

.underline {
  text-decoration: underline !important;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.hidden {
  display: none !important;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.al-start {
  align-items: flex-start;
}

.al-center {
  align-items: center;
}

.al-end {
  align-items: flex-end;
}

.js-start {
  justify-content: flex-start !important;
}

.js-center {
  justify-content: center;
}

.js-between {
  justify-content: space-between !important;
}

.js-around {
  justify-content: space-around !important;
}

.js-end {
  justify-content: flex-end;
}

.primary {
  color: var(--primary) !important;
}

.secondary {
  color: var(--secondary) !important;
}

.subText {
  color: var(--subText) !important;
}

.black {
  color: var(--black) !important;
}

.white {
  color: var(--white) !important;
}

.warn {
  color: var(--warn);
}

.success {
  color: var(--success);
}

.danger {
  color: var(--danger) !important;
}

.grey {
  color: var(--grey);
}

.MuiFormHelperText-root {
  margin: 10px 0 0 !important;
}

body.hide-sidebar .hambuger-icon mat-icon {
  transform: rotateY(180deg);
}
body.hide-sidebar aside {
  width: 68px !important;
}
body.hide-sidebar aside .mat-mdc-nav-list a {
  white-space: nowrap;
}
body.hide-sidebar aside .mat-mdc-nav-list a span {
  opacity: 0;
}
body.hide-sidebar aside .user-image {
  padding: 17px 10px !important;
}
body.hide-sidebar aside .user-image h6, body.hide-sidebar aside .user-image .expand-icon {
  opacity: 0;
}

.mat-mdc-menu-content {
  padding: 0 !important;
}

.timepicker-overlay {
  z-index: 1000 !important;
}

.cdk-global-scrollblock {
  overflow: auto;
}

.g-location-input {
  position: absolute;
  width: calc(100% + 40px) !important;
  top: 0;
  height: 100% !important;
  left: -20px;
  border-radius: 6px !important;
  padding: 0 20px !important;
}

button {
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}

.btn {
  padding: 16px 20px;
  font-size: 14px;
  border-radius: 10px;
  outline: none;
  height: 54px;
  line-height: 1.5;
  transition: 0.3s;
  background: var(--primaryLight);
  color: var(--primary);
  border: 1px solid var(--primaryLight);
  font-weight: 500;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn.btn-download {
  color: var(--white);
  background: var(--primary);
  border-color: var(--primary);
  height: 42px;
}
.btn.btn-primary {
  color: var(--white);
  background: var(--primary);
  border-color: var(--primary);
}
.btn.btn-primary-border {
  color: var(--primary);
  background: var(--white);
  border-color: var(--primary);
}
.btn.btn-secondary {
  color: var(--white);
  background: var(--secondary);
  border-color: var(--secondary);
}
.btn.btn-secondaryLight {
  color: var(--secondary);
  background: #e0f1e6;
  border-color: #e0f1e6;
}
.btn.btn-secondary-border {
  color: var(--secondary);
  background: var(--white);
  border-color: var(--secondary);
}
.btn.btn-gray {
  color: var(--gray);
  border-color: var(--grayBg);
  background-color: var(--grayBg);
}
.btn.btn-black-active {
  color: var(--primary);
  border-color: #2c2d2f;
  background-color: #202020;
}
.btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.btn.btn-draft {
  background-color: rgba(29, 185, 84, 0.07);
  color: #1db954;
  border-color: #1db954;
}
.btn.btn-black-cancel {
  color: var(--white);
  border-color: #303030;
  background-color: #191919;
}
.btn.btn-gray-cancel {
  background-color: #dfdfdf;
  color: #7a7a7a;
  border-color: #dfdfdf;
}
.btn.btn-white {
  background-color: var(--white);
  color: var(--gray);
  border-color: var(--grayBg);
}
.btn.btn-mini-fab {
  border-radius: 50%;
  padding: 10px 10px;
  font-size: 14px;
  height: auto;
}
.btn.btn-delete {
  color: var(--danger);
  border-color: rgb(255, 230, 230);
  background-color: rgb(255, 230, 230);
}
.btn.btnAdd {
  width: 90px;
  height: 30px;
  font-size: 14.25px;
  padding: 5px;
  border-radius: 4px;
  background: var(--grayBg);
  color: var(--gray);
}
.btn.btnRemove {
  width: 90px;
  height: 30px;
  font-size: 14.25px;
  padding: 5px;
  border-radius: 4px;
  background: var(--white);
  color: var(--error);
}
.btn.mw-100 {
  min-width: 190px;
}
.btn.large {
  padding: 17.5px 20px;
  font-size: 16px;
  height: 60px;
}
.btn .icon-right {
  padding-left: 4px;
}
.btn::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: -1;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  transform: skewX(-25deg);
}
.btn:hover::before {
  z-index: 1;
  animation: imageH 1s;
  -webkit-animation: imageH 1s;
  -moz-animation: imageH 1s;
  -ms-animation: imageH 1s;
  -o-animation: imageH 1s;
}
.btn:disabled:hover::before {
  display: none;
}
.btn.filterApplied {
  position: relative;
  overflow: initial;
}
.btn.filterApplied::before {
  display: none;
}
.btn.filterApplied::after {
  height: 10px;
  right: -4px;
  top: -4px;
  width: 10px;
  background: var(--danger);
  position: absolute;
  border-radius: 50%;
  content: "";
  display: block;
}

@-webkit-keyframes imageH {
  100% {
    left: 125%;
  }
}
@keyframes imageH {
  100% {
    left: 125%;
  }
}
.btnText {
  color: #1DB954;
  font-size: 14px;
  background: transparent;
  border: none;
  padding: 0;
  font-weight: 400;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mt-6 {
  margin-top: 6px !important;
}

.mb-6 {
  margin-bottom: 6px !important;
}

.ml-6 {
  margin-left: 6px !important;
}

.mr-6 {
  margin-right: 6px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mt-18 {
  margin-top: 18px !important;
}

.mb-18 {
  margin-bottom: 18px !important;
}

.ml-18 {
  margin-left: 18px !important;
}

.mr-18 {
  margin-right: 18px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mt-28 {
  margin-top: 28px !important;
}

.mb-28 {
  margin-bottom: 28px !important;
}

.ml-28 {
  margin-left: 28px !important;
}

.mr-28 {
  margin-right: 28px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.ml-32 {
  margin-left: 32px !important;
}

.mr-32 {
  margin-right: 32px !important;
}

.mt-36 {
  margin-top: 36px !important;
}

.mb-36 {
  margin-bottom: 36px !important;
}

.ml-36 {
  margin-left: 36px !important;
}

.mr-36 {
  margin-right: 36px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

@media screen and (max-width: 992px) {
  .mt-md-0 {
    margin-top: 0px !important;
  }
  .mb-md-0 {
    margin-bottom: 0px !important;
  }
  .ml-md-0 {
    margin-left: 0px !important;
  }
  .mr-md-0 {
    margin-right: 0px !important;
  }
  .mt-md-4 {
    margin-top: 4px !important;
  }
  .mb-md-4 {
    margin-bottom: 4px !important;
  }
  .ml-md-4 {
    margin-left: 4px !important;
  }
  .mr-md-4 {
    margin-right: 4px !important;
  }
  .mt-md-5 {
    margin-top: 5px !important;
  }
  .mb-md-5 {
    margin-bottom: 5px !important;
  }
  .ml-md-5 {
    margin-left: 5px !important;
  }
  .mr-md-5 {
    margin-right: 5px !important;
  }
  .mt-md-6 {
    margin-top: 6px !important;
  }
  .mb-md-6 {
    margin-bottom: 6px !important;
  }
  .ml-md-6 {
    margin-left: 6px !important;
  }
  .mr-md-6 {
    margin-right: 6px !important;
  }
  .mt-md-8 {
    margin-top: 8px !important;
  }
  .mb-md-8 {
    margin-bottom: 8px !important;
  }
  .ml-md-8 {
    margin-left: 8px !important;
  }
  .mr-md-8 {
    margin-right: 8px !important;
  }
  .mt-md-10 {
    margin-top: 10px !important;
  }
  .mb-md-10 {
    margin-bottom: 10px !important;
  }
  .ml-md-10 {
    margin-left: 10px !important;
  }
  .mr-md-10 {
    margin-right: 10px !important;
  }
  .mt-md-12 {
    margin-top: 12px !important;
  }
  .mb-md-12 {
    margin-bottom: 12px !important;
  }
  .ml-md-12 {
    margin-left: 12px !important;
  }
  .mr-md-12 {
    margin-right: 12px !important;
  }
  .mt-md-15 {
    margin-top: 15px !important;
  }
  .mb-md-15 {
    margin-bottom: 15px !important;
  }
  .ml-md-15 {
    margin-left: 15px !important;
  }
  .mr-md-15 {
    margin-right: 15px !important;
  }
  .mt-md-16 {
    margin-top: 16px !important;
  }
  .mb-md-16 {
    margin-bottom: 16px !important;
  }
  .ml-md-16 {
    margin-left: 16px !important;
  }
  .mr-md-16 {
    margin-right: 16px !important;
  }
  .mt-md-18 {
    margin-top: 18px !important;
  }
  .mb-md-18 {
    margin-bottom: 18px !important;
  }
  .ml-md-18 {
    margin-left: 18px !important;
  }
  .mr-md-18 {
    margin-right: 18px !important;
  }
  .mt-md-20 {
    margin-top: 20px !important;
  }
  .mb-md-20 {
    margin-bottom: 20px !important;
  }
  .ml-md-20 {
    margin-left: 20px !important;
  }
  .mr-md-20 {
    margin-right: 20px !important;
  }
  .mt-md-24 {
    margin-top: 24px !important;
  }
  .mb-md-24 {
    margin-bottom: 24px !important;
  }
  .ml-md-24 {
    margin-left: 24px !important;
  }
  .mr-md-24 {
    margin-right: 24px !important;
  }
  .mt-md-25 {
    margin-top: 25px !important;
  }
  .mb-md-25 {
    margin-bottom: 25px !important;
  }
  .ml-md-25 {
    margin-left: 25px !important;
  }
  .mr-md-25 {
    margin-right: 25px !important;
  }
  .mt-md-28 {
    margin-top: 28px !important;
  }
  .mb-md-28 {
    margin-bottom: 28px !important;
  }
  .ml-md-28 {
    margin-left: 28px !important;
  }
  .mr-md-28 {
    margin-right: 28px !important;
  }
  .mt-md-30 {
    margin-top: 30px !important;
  }
  .mb-md-30 {
    margin-bottom: 30px !important;
  }
  .ml-md-30 {
    margin-left: 30px !important;
  }
  .mr-md-30 {
    margin-right: 30px !important;
  }
  .mt-md-32 {
    margin-top: 32px !important;
  }
  .mb-md-32 {
    margin-bottom: 32px !important;
  }
  .ml-md-32 {
    margin-left: 32px !important;
  }
  .mr-md-32 {
    margin-right: 32px !important;
  }
  .mt-md-36 {
    margin-top: 36px !important;
  }
  .mb-md-36 {
    margin-bottom: 36px !important;
  }
  .ml-md-36 {
    margin-left: 36px !important;
  }
  .mr-md-36 {
    margin-right: 36px !important;
  }
  .mt-md-40 {
    margin-top: 40px !important;
  }
  .mb-md-40 {
    margin-bottom: 40px !important;
  }
  .ml-md-40 {
    margin-left: 40px !important;
  }
  .mr-md-40 {
    margin-right: 40px !important;
  }
}
@media screen and (max-width: 768px) {
  .mt-sm-0 {
    margin-top: 0px !important;
  }
  .mb-sm-0 {
    margin-bottom: 0px !important;
  }
  .ml-sm-0 {
    margin-left: 0px !important;
  }
  .mr-sm-0 {
    margin-right: 0px !important;
  }
  .mt-sm-4 {
    margin-top: 4px !important;
  }
  .mb-sm-4 {
    margin-bottom: 4px !important;
  }
  .ml-sm-4 {
    margin-left: 4px !important;
  }
  .mr-sm-4 {
    margin-right: 4px !important;
  }
  .mt-sm-5 {
    margin-top: 5px !important;
  }
  .mb-sm-5 {
    margin-bottom: 5px !important;
  }
  .ml-sm-5 {
    margin-left: 5px !important;
  }
  .mr-sm-5 {
    margin-right: 5px !important;
  }
  .mt-sm-6 {
    margin-top: 6px !important;
  }
  .mb-sm-6 {
    margin-bottom: 6px !important;
  }
  .ml-sm-6 {
    margin-left: 6px !important;
  }
  .mr-sm-6 {
    margin-right: 6px !important;
  }
  .mt-sm-8 {
    margin-top: 8px !important;
  }
  .mb-sm-8 {
    margin-bottom: 8px !important;
  }
  .ml-sm-8 {
    margin-left: 8px !important;
  }
  .mr-sm-8 {
    margin-right: 8px !important;
  }
  .mt-sm-10 {
    margin-top: 10px !important;
  }
  .mb-sm-10 {
    margin-bottom: 10px !important;
  }
  .ml-sm-10 {
    margin-left: 10px !important;
  }
  .mr-sm-10 {
    margin-right: 10px !important;
  }
  .mt-sm-12 {
    margin-top: 12px !important;
  }
  .mb-sm-12 {
    margin-bottom: 12px !important;
  }
  .ml-sm-12 {
    margin-left: 12px !important;
  }
  .mr-sm-12 {
    margin-right: 12px !important;
  }
  .mt-sm-15 {
    margin-top: 15px !important;
  }
  .mb-sm-15 {
    margin-bottom: 15px !important;
  }
  .ml-sm-15 {
    margin-left: 15px !important;
  }
  .mr-sm-15 {
    margin-right: 15px !important;
  }
  .mt-sm-16 {
    margin-top: 16px !important;
  }
  .mb-sm-16 {
    margin-bottom: 16px !important;
  }
  .ml-sm-16 {
    margin-left: 16px !important;
  }
  .mr-sm-16 {
    margin-right: 16px !important;
  }
  .mt-sm-18 {
    margin-top: 18px !important;
  }
  .mb-sm-18 {
    margin-bottom: 18px !important;
  }
  .ml-sm-18 {
    margin-left: 18px !important;
  }
  .mr-sm-18 {
    margin-right: 18px !important;
  }
  .mt-sm-20 {
    margin-top: 20px !important;
  }
  .mb-sm-20 {
    margin-bottom: 20px !important;
  }
  .ml-sm-20 {
    margin-left: 20px !important;
  }
  .mr-sm-20 {
    margin-right: 20px !important;
  }
  .mt-sm-24 {
    margin-top: 24px !important;
  }
  .mb-sm-24 {
    margin-bottom: 24px !important;
  }
  .ml-sm-24 {
    margin-left: 24px !important;
  }
  .mr-sm-24 {
    margin-right: 24px !important;
  }
  .mt-sm-25 {
    margin-top: 25px !important;
  }
  .mb-sm-25 {
    margin-bottom: 25px !important;
  }
  .ml-sm-25 {
    margin-left: 25px !important;
  }
  .mr-sm-25 {
    margin-right: 25px !important;
  }
  .mt-sm-28 {
    margin-top: 28px !important;
  }
  .mb-sm-28 {
    margin-bottom: 28px !important;
  }
  .ml-sm-28 {
    margin-left: 28px !important;
  }
  .mr-sm-28 {
    margin-right: 28px !important;
  }
  .mt-sm-30 {
    margin-top: 30px !important;
  }
  .mb-sm-30 {
    margin-bottom: 30px !important;
  }
  .ml-sm-30 {
    margin-left: 30px !important;
  }
  .mr-sm-30 {
    margin-right: 30px !important;
  }
  .mt-sm-32 {
    margin-top: 32px !important;
  }
  .mb-sm-32 {
    margin-bottom: 32px !important;
  }
  .ml-sm-32 {
    margin-left: 32px !important;
  }
  .mr-sm-32 {
    margin-right: 32px !important;
  }
  .mt-sm-36 {
    margin-top: 36px !important;
  }
  .mb-sm-36 {
    margin-bottom: 36px !important;
  }
  .ml-sm-36 {
    margin-left: 36px !important;
  }
  .mr-sm-36 {
    margin-right: 36px !important;
  }
  .mt-sm-40 {
    margin-top: 40px !important;
  }
  .mb-sm-40 {
    margin-bottom: 40px !important;
  }
  .ml-sm-40 {
    margin-left: 40px !important;
  }
  .mr-sm-40 {
    margin-right: 40px !important;
  }
}
@media screen and (max-width: 576px) {
  .mt-xs-0 {
    margin-top: 0px !important;
  }
  .mb-xs-0 {
    margin-bottom: 0px !important;
  }
  .ml-xs-0 {
    margin-left: 0px !important;
  }
  .mr-xs-0 {
    margin-right: 0px !important;
  }
  .mt-xs-4 {
    margin-top: 4px !important;
  }
  .mb-xs-4 {
    margin-bottom: 4px !important;
  }
  .ml-xs-4 {
    margin-left: 4px !important;
  }
  .mr-xs-4 {
    margin-right: 4px !important;
  }
  .mt-xs-5 {
    margin-top: 5px !important;
  }
  .mb-xs-5 {
    margin-bottom: 5px !important;
  }
  .ml-xs-5 {
    margin-left: 5px !important;
  }
  .mr-xs-5 {
    margin-right: 5px !important;
  }
  .mt-xs-6 {
    margin-top: 6px !important;
  }
  .mb-xs-6 {
    margin-bottom: 6px !important;
  }
  .ml-xs-6 {
    margin-left: 6px !important;
  }
  .mr-xs-6 {
    margin-right: 6px !important;
  }
  .mt-xs-8 {
    margin-top: 8px !important;
  }
  .mb-xs-8 {
    margin-bottom: 8px !important;
  }
  .ml-xs-8 {
    margin-left: 8px !important;
  }
  .mr-xs-8 {
    margin-right: 8px !important;
  }
  .mt-xs-10 {
    margin-top: 10px !important;
  }
  .mb-xs-10 {
    margin-bottom: 10px !important;
  }
  .ml-xs-10 {
    margin-left: 10px !important;
  }
  .mr-xs-10 {
    margin-right: 10px !important;
  }
  .mt-xs-12 {
    margin-top: 12px !important;
  }
  .mb-xs-12 {
    margin-bottom: 12px !important;
  }
  .ml-xs-12 {
    margin-left: 12px !important;
  }
  .mr-xs-12 {
    margin-right: 12px !important;
  }
  .mt-xs-15 {
    margin-top: 15px !important;
  }
  .mb-xs-15 {
    margin-bottom: 15px !important;
  }
  .ml-xs-15 {
    margin-left: 15px !important;
  }
  .mr-xs-15 {
    margin-right: 15px !important;
  }
  .mt-xs-16 {
    margin-top: 16px !important;
  }
  .mb-xs-16 {
    margin-bottom: 16px !important;
  }
  .ml-xs-16 {
    margin-left: 16px !important;
  }
  .mr-xs-16 {
    margin-right: 16px !important;
  }
  .mt-xs-18 {
    margin-top: 18px !important;
  }
  .mb-xs-18 {
    margin-bottom: 18px !important;
  }
  .ml-xs-18 {
    margin-left: 18px !important;
  }
  .mr-xs-18 {
    margin-right: 18px !important;
  }
  .mt-xs-20 {
    margin-top: 20px !important;
  }
  .mb-xs-20 {
    margin-bottom: 20px !important;
  }
  .ml-xs-20 {
    margin-left: 20px !important;
  }
  .mr-xs-20 {
    margin-right: 20px !important;
  }
  .mt-xs-24 {
    margin-top: 24px !important;
  }
  .mb-xs-24 {
    margin-bottom: 24px !important;
  }
  .ml-xs-24 {
    margin-left: 24px !important;
  }
  .mr-xs-24 {
    margin-right: 24px !important;
  }
  .mt-xs-25 {
    margin-top: 25px !important;
  }
  .mb-xs-25 {
    margin-bottom: 25px !important;
  }
  .ml-xs-25 {
    margin-left: 25px !important;
  }
  .mr-xs-25 {
    margin-right: 25px !important;
  }
  .mt-xs-28 {
    margin-top: 28px !important;
  }
  .mb-xs-28 {
    margin-bottom: 28px !important;
  }
  .ml-xs-28 {
    margin-left: 28px !important;
  }
  .mr-xs-28 {
    margin-right: 28px !important;
  }
  .mt-xs-30 {
    margin-top: 30px !important;
  }
  .mb-xs-30 {
    margin-bottom: 30px !important;
  }
  .ml-xs-30 {
    margin-left: 30px !important;
  }
  .mr-xs-30 {
    margin-right: 30px !important;
  }
  .mt-xs-32 {
    margin-top: 32px !important;
  }
  .mb-xs-32 {
    margin-bottom: 32px !important;
  }
  .ml-xs-32 {
    margin-left: 32px !important;
  }
  .mr-xs-32 {
    margin-right: 32px !important;
  }
  .mt-xs-36 {
    margin-top: 36px !important;
  }
  .mb-xs-36 {
    margin-bottom: 36px !important;
  }
  .ml-xs-36 {
    margin-left: 36px !important;
  }
  .mr-xs-36 {
    margin-right: 36px !important;
  }
  .mt-xs-40 {
    margin-top: 40px !important;
  }
  .mb-xs-40 {
    margin-bottom: 40px !important;
  }
  .ml-xs-40 {
    margin-left: 40px !important;
  }
  .mr-xs-40 {
    margin-right: 40px !important;
  }
}
:root {
  --white: #ffffff;
  --black: #000000;
  --primary: #0164C1;
  --primaryLight: #dde7f1;
  --secondary: #1DB954;
  --warn: #FE9820;
  --danger: #EC1D1D;
  --error: #FF3B30;
  --success: #28c879;
  --subText: #7b7b7b;
  --gray: #727272;
  --grayBg: #EDEDED;
  --mat-option-label-text-font: "Poppins", san-serif;
  --mat-select-trigger-text-size: 15px;
}

.popup-ui {
  padding: 30px;
  border-radius: 20px;
  background: #FFF;
  overflow: auto;
  max-height: 90vh;
  max-width: 100%;
}
.popup-ui.extra-large {
  width: 800px;
}
.popup-ui.large {
  width: 650px;
}
.popup-ui.medium {
  width: 500px;
}
.popup-ui.small {
  width: 480px;
}
.popup-ui .popup-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.popup-ui .popup-header.js-center {
  justify-content: center;
}
.popup-ui .popup-header .close-icon-button {
  padding: 0px !important;
  background: transparent !important;
  width: 48px;
  height: 48px;
  border: none;
  cursor: pointer;
}
.popup-ui .popup-header .close-icon-button img {
  display: table-cell;
  width: 100%;
  height: 100%;
}
.popup-ui .popup-body {
  padding-top: 20px;
}

.cdk-overlay-pane.large-dialog {
  width: 500px;
}
.cdk-overlay-pane .mat-mdc-dialog-container {
  max-height: 90vh;
  overflow: auto;
}
.cdk-overlay-pane .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  overflow: hidden;
}
.cdk-overlay-pane.sub-event-detail-dilaog {
  width: 900px;
}
.cdk-overlay-pane.sub-event-detail-dilaog .img-cover {
  width: 100%;
  border-radius: 10px;
}
.cdk-overlay-pane.sub-event-detail-dilaog .img-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.row {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -15px -13px;
}
.row .col-1,
.row .col-2,
.row .col-3,
.row .col-4,
.row .col-5,
.row .col-6,
.row .col-7,
.row .col-8,
.row .col-9,
.row .col-10,
.row .col-11,
.row .col-12 {
  position: relative;
  padding: 15px 13px;
  max-width: auto !important;
}

.row.detail-cover {
  margin: -12px;
}
.row.detail-cover .col-1,
.row.detail-cover .col-2,
.row.detail-cover .col-3,
.row.detail-cover .col-4,
.row.detail-cover .col-5,
.row.detail-cover .col-6,
.row.detail-cover .col-7,
.row.detail-cover .col-8,
.row.detail-cover .col-9,
.row.detail-cover .col-10,
.row.detail-cover .col-11,
.row.detail-cover .col-12 {
  padding: 12px;
}

.col-1 {
  width: 8.3333333333%;
}

.col-2 {
  width: 16.6666666667%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.3333333333%;
}

.col-5 {
  width: 41.6666666667%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.3333333333%;
}

.col-8 {
  width: 66.6666666667%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.3333333333%;
}

.col-11 {
  width: 91.6666666667%;
}

.col-12 {
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .col-xl-1 {
    width: 8.3333333333%;
  }
  .col-xl-2 {
    width: 16.6666666667%;
  }
  .col-xl-3 {
    width: 25%;
  }
  .col-xl-4 {
    width: 33.3333333333%;
  }
  .col-xl-5 {
    width: 41.6666666667%;
  }
  .col-xl-6 {
    width: 50%;
  }
  .col-xl-7 {
    width: 58.3333333333%;
  }
  .col-xl-8 {
    width: 66.6666666667%;
  }
  .col-xl-9 {
    width: 75%;
  }
  .col-xl-10 {
    width: 83.3333333333%;
  }
  .col-xl-11 {
    width: 91.6666666667%;
  }
  .col-xl-12 {
    width: 100%;
  }
}
@media screen and (max-width: 992px) {
  .col-lg-1 {
    width: 8.3333333333%;
  }
  .col-lg-2 {
    width: 16.6666666667%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-4 {
    width: 33.3333333333%;
  }
  .col-lg-5 {
    width: 41.6666666667%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-7 {
    width: 58.3333333333%;
  }
  .col-lg-8 {
    width: 66.6666666667%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-10 {
    width: 83.3333333333%;
  }
  .col-lg-11 {
    width: 91.6666666667%;
  }
  .col-lg-12 {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .col-md-1 {
    width: 8.3333333333%;
  }
  .col-md-2 {
    width: 16.6666666667%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33.3333333333%;
  }
  .col-md-5 {
    width: 41.6666666667%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58.3333333333%;
  }
  .col-md-8 {
    width: 66.6666666667%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83.3333333333%;
  }
  .col-md-11 {
    width: 91.6666666667%;
  }
  .col-md-12 {
    width: 100%;
  }
}
@media screen and (max-width: 576px) {
  .col-sm-1 {
    width: 8.3333333333%;
  }
  .col-sm-2 {
    width: 16.6666666667%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-4 {
    width: 33.3333333333%;
  }
  .col-sm-5 {
    width: 41.6666666667%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-7 {
    width: 58.3333333333%;
  }
  .col-sm-8 {
    width: 66.6666666667%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-10 {
    width: 83.3333333333%;
  }
  .col-sm-11 {
    width: 91.6666666667%;
  }
  .col-sm-12 {
    width: 100%;
  }
}
.custome-class {
  --clock-hand-color: var(--primary);
}
.custome-class .timepicker__header {
  background-color: var(--primary) !important;
}
.custome-class .timepicker__main-content .timepicker__body ngx-material-timepicker-12-hours-face ngx-material-timepicker-face .clock-face .clock-face__container .active {
  background-color: var(--primary) !important;
}
.custome-class .timepicker__actions ngx-material-timepicker-button .timepicker-button {
  color: var(--primary) !important;
}