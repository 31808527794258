h1,
h2,
h3,
h4,
h5,
h6,
p,
label {
  margin: 0;
  line-height: 1.4;
  font-family: "Poppins", sans-serif !important;
}
label{
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6{
  font-weight: 600;
}

h2 {
  font-size: 32px;
  &.large {
    font-size: 36px;
  }

  &.ex-large {
    font-size: 42px;
  }
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
  &.large{
    font-size: 22px;
  }
}

h6 {
  font-size: 18px;

  &.small {
    font-size: 16px;
  }
  &.ex-small {
    font-size: 14px;
  }
}

p {
  font-size: 16px;

  &.ex-small {
    font-size: 12px;
  }

  &.small {
    font-size: 14px;
  }

  
  &.large {
    font-size: 18px;
  }
  
  &.ex-large {
    font-size: 20px;
  }
}

.fs-15{
  font-size: 15px !important;
}

@media screen and (max-width: 768px) {
  h2 {
    font-size: 28px;
    &.large {
      font-size: 22px;
    }
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 18px;
    &.large{
      font-size: 20px;
    }
  }
  h6{
    font-size: 16px;
    &.small{
      font-size: 14px;
    }
    &.ex-small{
      font-size: 13px;
    }
  }
  p{
    font-size: 15px;
    &.large {
      font-size: 16px;
    }
  }
  .fs-15 {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 576px) {
  h2 {
   font-size: 22px;
  }
  h3{
    font-size: 20px;
  }
  h4{
    font-size: 18px;
  }
  h5 {
    font-size: 15px;
  }
  h6{
    &.ex-small{
      font-size: 12px;
    }
  }
  p {
    font-size: 14px;
    &.ex-small{
      font-size: 10px;
    }
    &.small {
      font-size: 12px;
    }
    &.large {
      font-size: 14px;
    }
  }
  .fs-15 {
    font-size: 13px !important;
  }
}