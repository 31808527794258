* {
  box-sizing: border-box;
  letter-spacing: 0 !important;
}
html,
body {
  height: 100%;
}
ul,
ol,
dl {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.visuallyHidden {
  position: fixed;
  overflow: hidden;
  opacity: 0;
  width: 0;
  height: 0;
}

body {
  font-family: 'Poppins', sans-serif;
  padding: 0;
  margin: 0;
  overflow: hidden overlay;

  @supports (-webkit-appearance: none) {
    overflow: overlay;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  background: #DDDBDB;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary);
}

::-webkit-scrollbar-corner {
  background-color: transparent;
  border-color: transparent;
}

a {
  text-decoration: none;
}

.light {
  font-weight: 300 !important;
}

.regular {
  font-weight: 400 !important;
}

.medium {
  font-weight: 500 !important;
  .mdc-label{
    font-weight: 500 !important;
  }
}

.semi-bold {
  font-weight: 600 !important;
}

.bold {
  font-weight: 700 !important;
}

.extra-bold {
  font-weight: 800 !important;
}

.nowrap {
  white-space: nowrap;
}
.break-all {
  word-break: break-all;
}

.rounded {
  border-radius: 4px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize !important;
}

.left {
  text-align: left !important;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.inline-block {
  display: inline-block;
}

.pointer {
  cursor: pointer;
}

.underline {
  text-decoration: underline !important;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.hidden {
  display: none !important;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.al-start {
  align-items: flex-start;
}

.al-center {
  align-items: center;
}

.al-end {
  align-items: flex-end;
}

.js-start {
  justify-content: flex-start !important;
}

.js-center {
  justify-content: center;
}

.js-between {
  justify-content: space-between !important;
}

.js-around {
  justify-content: space-around !important;
}

.js-end {
  justify-content: flex-end;
}

.primary {
  color: var(--primary) !important;
}
.secondary {
  color: var(--secondary) !important;
}

.subText {
  color: var(--subText) !important;
}
.black {
  color: var(--black) !important;
}

.white {
  color: var(--white) !important;
}

.warn {
  color: var(--warn);
}

.success {
  color: var(--success);
}

.danger {
  color: var(--danger) !important;
}

.grey {
  color: var(--grey);
}

.MuiFormHelperText-root {
  margin: 10px 0 0 !important;
}

body {
  &.hide-sidebar {
    .hambuger-icon {
      mat-icon {
        transform: rotateY(180deg);
      }
    }

    aside {
      width: 68px !important;
      .mat-mdc-nav-list {
        a {
          white-space: nowrap;

          span {
            opacity: 0;
          }
        }
      }
      .user-image{
        padding: 17px 10px !important;
        h6, .expand-icon{
          opacity: 0;
        }
      }
    }
  }
}

.mat-mdc-menu-content {
  padding: 0 !important;
}

.timepicker-overlay {
  z-index: 1000 !important;
}

.cdk-global-scrollblock {
  overflow: auto;
}

.g-location-input {
  position: absolute;
  width: calc(100% + 40px) !important;
  top: 0;
  height: 100% !important;
  left: -20px;
  border-radius: 6px !important;
  padding: 0 20px !important;
}
