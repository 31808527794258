.row {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: -15px -13px;
    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12 {
        position: relative;
        padding: 15px 13px;
        max-width: auto !important;
    }
}


.row{
    &.detail-cover{
        margin: -12px;
        .col-1,
        .col-2,
        .col-3,
        .col-4,
        .col-5,
        .col-6,
        .col-7,
        .col-8,
        .col-9,
        .col-10,
        .col-11,
        .col-12 {
            padding: 12px;
        }
    }
}

.col-1 {
    width: calc(100% / 12);
}

.col-2 {
    width: calc((100% / 12) * 2);
}

.col-3 {
    width: calc((100% / 12) * 3);
}

.col-4 {
    width: calc((100% / 12) * 4);
}

.col-5 {
    width: calc((100% / 12) * 5);
}

.col-6 {
    width: calc((100% / 12) * 6);
}

.col-7 {
    width: calc((100% / 12) * 7);
}

.col-8 {
    width: calc((100% / 12) * 8);
}

.col-9 {
    width: calc((100% / 12) * 9);
}

.col-10 {
    width: calc((100% / 12) * 10);
}

.col-11 {
    width: calc((100% / 12) * 11);
}

.col-12 {
    width: 100%;
}

@media screen and (max-width: 1200px) {
    .col-xl-1 {
        width: calc(100% / 12);
    }

    .col-xl-2 {
        width: calc((100% / 12) * 2);
    }

    .col-xl-3 {
        width: calc((100% / 12) * 3);
    }

    .col-xl-4 {
        width: calc((100% / 12) * 4);
    }

    .col-xl-5 {
        width: calc((100% / 12) * 5);
    }

    .col-xl-6 {
        width: calc((100% / 12) * 6);
    }

    .col-xl-7 {
        width: calc((100% / 12) * 7);
    }

    .col-xl-8 {
        width: calc((100% / 12) * 8);
    }

    .col-xl-9 {
        width: calc((100% / 12) * 9);
    }

    .col-xl-10 {
        width: calc((100% / 12) * 10);
    }

    .col-xl-11 {
        width: calc((100% / 12) * 11);
    }

    .col-xl-12 {
        width: 100%;
    }
}

@media screen and (max-width: 992px) {
    .col-lg-1 {
        width: calc(100% / 12);
    }

    .col-lg-2 {
        width: calc((100% / 12) * 2);
    }

    .col-lg-3 {
        width: calc((100% / 12) * 3);
    }

    .col-lg-4 {
        width: calc((100% / 12) * 4);
    }

    .col-lg-5 {
        width: calc((100% / 12) * 5);
    }

    .col-lg-6 {
        width: calc((100% / 12) * 6);
    }

    .col-lg-7 {
        width: calc((100% / 12) * 7);
    }

    .col-lg-8 {
        width: calc((100% / 12) * 8);
    }

    .col-lg-9 {
        width: calc((100% / 12) * 9);
    }

    .col-lg-10 {
        width: calc((100% / 12) * 10);
    }

    .col-lg-11 {
        width: calc((100% / 12) * 11);
    }

    .col-lg-12 {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .col-md-1 {
        width: calc(100% / 12);
    }

    .col-md-2 {
        width: calc((100% / 12) * 2);
    }

    .col-md-3 {
        width: calc((100% / 12) * 3);
    }

    .col-md-4 {
        width: calc((100% / 12) * 4);
    }

    .col-md-5 {
        width: calc((100% / 12) * 5);
    }

    .col-md-6 {
        width: calc((100% / 12) * 6);
    }

    .col-md-7 {
        width: calc((100% / 12) * 7);
    }

    .col-md-8 {
        width: calc((100% / 12) * 8);
    }

    .col-md-9 {
        width: calc((100% / 12) * 9);
    }

    .col-md-10 {
        width: calc((100% / 12) * 10);
    }

    .col-md-11 {
        width: calc((100% / 12) * 11);
    }

    .col-md-12 {
        width: 100%;
    }
}

@media screen and (max-width: 576px) {
    .col-sm-1 {
        width: calc(100% / 12);
    }

    .col-sm-2 {
        width: calc((100% / 12) * 2);
    }

    .col-sm-3 {
        width: calc((100% / 12) * 3);
    }

    .col-sm-4 {
        width: calc((100% / 12) * 4);
    }

    .col-sm-5 {
        width: calc((100% / 12) * 5);
    }

    .col-sm-6 {
        width: calc((100% / 12) * 6);
    }

    .col-sm-7 {
        width: calc((100% / 12) * 7);
    }

    .col-sm-8 {
        width: calc((100% / 12) * 8);
    }

    .col-sm-9 {
        width: calc((100% / 12) * 9);
    }

    .col-sm-10 {
        width: calc((100% / 12) * 10);
    }

    .col-sm-11 {
        width: calc((100% / 12) * 11);
    }

    .col-sm-12 {
        width: 100%;
    }
}