.loader-cover {
  width: 100vw;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  height: 100vh;
  z-index: 99;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  lottie-player {
    height: 350px;
  }
}
.color-red {
  color: red;
}
.MuiFormControl-root,
.MuiFormControl-root .MuiTypography-root,
.MuiInputBase-input,
.otp-field__input,
.filter-cover,
.mat-expansion-panel-header,
.mdc-form-field,
.mat-mdc-paginator-container,
.mat-mdc-form-field {
  font-family: 'Poppins', sans-serif !important;
}
.delete-button-for-header-cover {
  .delete-button-for-header {
    box-shadow: none !important;
    img{
      display: table-cell;
    }
  }
}
.MuiLink-root {
  cursor: pointer;
  text-decoration: inherit !important;
}

.container {
  max-width: 1300px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

img {
  max-width: 100%;
}

figure {
  margin: 0;
  line-height: 0.6;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.MuiLinearProgress-bar {
  background-color: var(--primary) !important;
}

.MuiTooltip-tooltip {
  background-color: var(--white) !important;
  color: var(--subText) !important;
  box-shadow: 0px 2px 24px 0px #00000029;
  padding: 12px 16px !important;
  line-height: 15px !important;

  @media screen and (max-width: 576px) {
    font-size: 11px !important;
  }

  .MuiTooltip-arrow {
    color: var(--white) !important;
  }
}

.MuiDialog-container {
  .MuiPaper-root {
    margin: 32px 16px;
    width: calc(100% - 32px);
    width: calc(100% - 32px);
  }
}

.MuiSnackbar-root {
  top: 50px;
  bottom: auto !important;
  left: 50% !important;
  right: auto !important;
  transform: translateX(-50%);
  width: max-content;
  max-width: 750px;

  @media screen and (max-width: 768px) {
    max-width: calc(100% - 60px);
  }

  @media screen and (max-width: 576px) {
    max-width: calc(100% - 32px);
  }

  .MuiPaper-root {
    padding: 6px 48px;
    border-radius: 0px;
    font-family: 'Monument', sans-serif;
    text-align: center;
    justify-content: center;

    @media screen and (max-width: 992px) {
      padding: 4px 24px;
      font-size: 14px;
    }

    @media screen and (max-width: 768px) {
      padding: 3px 20px;
      font-size: 13px;
    }

    @media screen and (max-width: 576px) {
      padding: 2px 16px;
      font-size: 12px;
    }
  }

  &.success {
    .MuiPaper-root {
      background: var(--success);
    }
  }

  &.danger {
    .MuiPaper-root {
      background: var(--danger);
    }
  }
}

.MuiMenu-list {
  max-height: 300px;
}

.MuiCheckbox-root {
  min-width: 38px;
  margin-top: -9px !important;
  margin-left: -9px !important;
  padding: 9px !important;
  border-radius: 50% !important;
}

@media screen and (max-width: 576px) {
  .MuiCheckbox-root {
    margin-top: -11px !important;
  }

  .MuiFormControlLabel-root {
    margin-right: 0 !important;
  }
}

.custom-slider {
  position: relative;
  display: inline-block;
  width: 43px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 34px;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: '';
      height: 16px;
      width: 16px;
      left: 5px;
      bottom: 4px;
      border-radius: 50%;
      background-color: var(--white);
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
}

.close-btn-icon {
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
}

.MuiDialog-root {
  z-index: 9999 !important;
}

.form-field-cover {
  label {
    display: inline-flex;
    font-size: 16px;
    margin-bottom: 9px;
    line-height: 1.5;
    font-weight: 500;
    color: var(--black);
  }

  .mat-mdc-form-field {
    width: 100%;

    .mdc-text-field {
      background-color: #fbfbfb;
      border-radius: 9px;
      padding-left: 21.5px;
      padding-right: 21.5px;

      .mat-mdc-form-field-infix {
        padding-top: 15px;
        padding-bottom: 15px;
        min-height: 55px;
      }

      input,
      textarea {
        font-size: 15px;
        letter-spacing: 0;
        &::placeholder {
          color: #7b7b7b;
        }
      }

      .mdc-notched-outline {
        div {
          border-color: #e2e2e2;

          &.mdc-notched-outline__leading {
            border-radius: 10px 0 0 10px;
          }

          &.mdc-notched-outline__trailing {
            border-radius: 0 10px 10px 0;
          }
        }
      }
    }
    .mat-mdc-form-field-icon-suffix {
      margin-right: -10px;
    }
  }

  &.search-bar {
    width: 370px;

    .mat-mdc-form-field {
      .mdc-text-field {
        background-color: #fff;
        padding-left: 20px;
        padding-right: 20px;

        .mat-mdc-form-field-infix {
          padding-top: 15px;
          padding-bottom: 15px;
          min-height: 55px;
        }

        input {
          font-size: 15px;

          &::-webkit-search-cancel-button {
            cursor: pointer;
          }

          &::placeholder {
            color: #767676;
          }
        }
      }

      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }
  }
}
.mat-mdc-select {
  font-family: "Poppins", sans-serif !important;
  .mat-mdc-select-placeholder {
    color: #8f8996;
    font-size: 15px;
  }
}

.mdc-label {
  color: var(--black);
  font-weight: 400;
  font-size: 16px;
}


.mdc-checkbox{
  margin-left: -11px !important;
  .mdc-checkbox__ripple{
    background-color: #d3d3d3 !important;
  }
  .mat-mdc-focus-indicator{
    display: none;
  }
}
.mdc-radio{
  margin-left: -10px !important;
}

.mat-mdc-checkbox {

  .mat-mdc-checkbox-touch-target {
    width: 100%;
    height: 100%;
  }

  .mdc-checkbox__background {
    color: #8e8e8e;
    border-width: 1px;
    background-color: transparent !important;
    border-color: revert !important;
    svg {
      color: transparent !important;
    }

    .mdc-checkbox__checkmark-path {
      stroke-width: 2px;
    }
  }

  &.mat-mdc-checkbox-checked {
    .mdc-checkbox__background {
      background-color: var(--primary) !important;
      border-color: var(--primary) !important;

      .mdc-checkbox__checkmark-path {
        color: var(--white) !important;
      }
    }
  }
  &.mdc-checkbox--disabled {
    opacity: 0.5;
  }
}
.profile-menu {
  width: 256px;
  padding: 7px;
  .mat-mdc-menu-item {
    .mat-mdc-menu-item-text {
      font-family: 'Poppins', sans-serif !important;
      font-weight: 500 !important;
    }
    &:not(:last-child) {
      border-bottom: 1px solid #dedede;
    }
  }
}

.mat-mdc-form-field-subscript-wrapper {
  display: grid;
  &::before {
    display: none !important;
  }

  .mat-mdc-form-field-error-wrapper,
  .mat-mdc-form-field-hint-wrapper {
    padding: 0 !important;
    position: inherit;
  }

  mat-error {
    font-size: 14px;
    color: var(--error);
    font-weight: 500;
    text-align: right;
    padding-top: 7px;
    line-height: 18px;
    font-family: 'Poppins', sans-serif !important;
    &::first-letter {
      text-transform: uppercase !important;
    }
  }
  mat-hint {
    font-size: 14px;
    color: var(--subText);
    // font-weight: 500;
    text-align: right;
    padding-top: 7px;
    line-height: 18px;
    font-family: 'Poppins', sans-serif !important;
    &::first-letter {
      text-transform: uppercase !important;
    }
  }
}

.click-here {
  margin-top: 8px;
  transition: 0.2s;
  position: relative;
  span {
    color: #1db954;
    cursor: pointer;
    font-weight: 500;
  }
}

.upload-img-cover {
  width: 100%;
  aspect-ratio: 2/1;
  border-radius: 10px;
  margin: auto;

  .upload-btn {
    width: 100%;
    height: 100%;
    background: #f2f7fd;
    border-radius: 10px;
    padding: 0;
    overflow: hidden;
    position: relative;
    cursor: pointer;

    .mdc-button__label {
      min-width: 100% !important;
      min-height: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }

    img {
      display: table-cell;
    }
  }
  .img-cover {
    background: #f7f7f7;
    border-radius: 10px;
  }
  &.circular {
    border-radius: 50%;
    aspect-ratio: 1/1;
    width: 220px;
    margin-left: 0;
    .img-cover {
      border-radius: 50%;
    }
    .upload-btn {
      border-radius: 50%;
    }
    .remove-cover {
      right: 20px !important;
      top: 20px !important;
    }
  }
}

.bg-white {
  border-radius: 13px;
  background: #fff;
  box-shadow: 0px 4px 8px 8px rgba(0, 0, 0, 0.01);
  padding: 32px 22px;
}

.bg-gray {
  border-radius: 13px;
  background: #f6f6f6;
  box-shadow: 0px 4px 8px 8px rgba(0, 0, 0, 0.01);
  padding: 22px;
}

.status-cover {
  font-size: 14px;
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 20px;
  white-space: nowrap;
  &.success {
    background: #e8f9ee;
    color: var(--secondary);
  }

  &.upcoming {
    background: #e8f1f9;
    color: #0164c1;
  }

  &.danger {
    background-color: #ffe3e3;
    color: var(--danger);
  }

  &.pending {
    color: #ff7c00;
    background-color: #fff2e5;
  }
}

.detail-cover {
  label {
    display: inline-block;
    color: var(--subText);
    margin-bottom: 8px;
    font-size: 15px;
  }

  .value {
    font-size: 15px;
    font-weight: 500;
    word-break: break-all;
  }
}

.mat-mdc-tab-links {
  .mdc-tab {
    width: 250px;
    flex-grow: initial !important;
    .mdc-tab__text-label {
      font-size: 16px;
    }
    .mdc-tab-indicator {
      .mdc-tab-indicator__content {
        border-color: #ececec;
        opacity: 1;
      }
    }

    &.active-link {
      .mdc-tab__text-label {
        color: black !important;
        font-weight: 600;
      }

      .mdc-tab-indicator__content {
        border-color: var(--primary) !important;
        // border-width: 5px;
      }
    }
  }
}

.profile-image-cover {
  width: 165px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  overflow: hidden;
  max-width: 70%;
  border: 1px solid #e2e2e2;
  margin: auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.mat-mdc-radio-button {
  &.mat-mdc-radio-checked {
    .mdc-radio__background {
      &::before {
        background-color: var(--primary) !important;
      }

      div {
        border-color: var(--primary) !important;
      }
    }
  }
}

.mat-mdc-radio-group {
  .mat-mdc-radio-button {
    .mdc-form-field {
      width: 100%;
      display: flex;
      align-items: center;
      letter-spacing: 0;
      label {
        font-weight: 500;
        margin-bottom: 0;
        color: black;
        letter-spacing: 0;
      }
    }
  }
  &.organizer-radio-buttons {
    .mat-mdc-radio-button {
      .mdc-form-field {
        flex-direction: row !important;
        border-bottom: 1px solid #d9d9d9;

        padding: 10px 0px;
        .mdc-label {
          width: 100%;
          span {
            font-weight: 400;
            color: gray;
          }
        }
      }
    }
  }
}
.cdk-overlay-pane {
  .mat-mdc-menu-panel {
    max-width: 300px;
    // overflow: hidden;
    .mat-mdc-menu-content {
      overflow: auto;
    }
  }
}

.slideImage {
  display: none;
}

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

.mat-mdc-menu-panel {
  border-radius: 10px !important;
}

.cc-dropdown-cover {
  button {
    background: transparent;
    border: none;
    padding: 12px 7px 12px 14px;
    position: relative;
    margin-right: 10px;
    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 8px;
      bottom: 8px;
      width: 1px;
      background: #e1e1e1;
    }
  }

  .mat-mdc-text-field-wrapper {
    padding-left: 0 !important;
  }
}
.bordered-card {
  border-radius: 13px;
  background: #fff;
  border: 1px solid #d3d3d3;
  padding: 15px;
  &.link-added {
    background: var(--primary);
  }
  &.padding-30 {
    padding: 30px;
  }
  &.inPreview {
    padding: 32px 22px;
    box-shadow: 0 4px 8px 8px rgba(0, 0, 0, 0.01);
  }
  &.border-2px{
    border: 2px solid var(--black);
  }
}
.light-blue-card {
  border-radius: 14px;
  background: #f2f7fd;
  border: 1px solid #a9cbea;
  padding: 13px 9px 15px;
  .bordered-card {
    padding: 13px 8px;
  }
}

.mat-mdc-select-panel {
  input {
    &.filter {
      width: calc(100% - 40px);
      margin: 10px 20px;
      padding: 12px 15px;
      font-size: 16px;
      border-radius: 5px;
      border: 1px solid #a6a6a6;
      font-family: 'Poppins', sans-serif;
    }
  }
}

.mat-mdc-menu-item:not([disabled]):hover {
  background-color: #eaeaea;
}

.page-create-event {
  .mat-mdc-form-field-has-icon-prefix {
    .mat-mdc-text-field-wrapper {
      padding-left: 0 !important;
    }
  }
  .mat-stepper-horizontal {
    &.StepperForClassPreview {
      .mat-horizontal-content-container {
        padding: 0 0 30px !important;
        border-radius: 13px;
        .page-event-detail {
          .singlePreviewWithBorder {
            padding: 30px;
            border-radius: 13px;
            border: 1px solid var(--black);
            &:not(:last-child) {
              margin-bottom: 60px;
            }
          }
        }
      }
    }
    &.StepperForSubEvent {
      .mat-horizontal-content-container {
        padding: 0 0 30px !important;
        border-radius: 13px;
        .page-event-detail {
          .singlePreviewWithBorder {
            padding: 30px;
            border-radius: 13px;
            border: 1px solid var(--black);
            &:not(:last-child) {
              margin-bottom: 60px;
            }
          }
        }
      }
    }
  }
}

.form-for-inc-dec {
  .mat-mdc-form-field-icon-suffix {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    margin-right: -12px;
  }
  .counter-btn {
    background: #e7e7e7;
    box-shadow: none;
    padding: 0;
    width: 27px;
    height: 22px;
    overflow: hidden;
    span {
      border-radius: 0 !important;
    }
    .mat-icon {
      color: black !important;
    }
    &.btn-top {
      border-radius: 6px 6px 0 0;
    }
    &.btn-down {
      border-radius: 0 0 6px 6px;
    }
  }
}
.form-for-hour{
  position: relative;
  .hour-text{
    position: absolute;
    left: 16px;
  }
}
.ticket-accordion {
  & > form {
    & > .mat-expansion-panel {
      box-shadow: none !important;
      border: 1px solid #e2e2e2;
      border-radius: 8px !important;
      & > .mat-expansion-panel-header {
        height: auto !important;
        padding: 20px 16px;
        height: 78px !important;
        &:hover {
          background: var(--white) !important;
        }
        .mat-expansion-indicator {
          display: none;
        }
        .mat-content {
          align-items: center;
          padding-right: 20px;
          position: relative;
          .mat-icon {
            &.chevron {
              position: absolute;
              right: 0px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
        &.mat-expanded {
          .mat-content {
            .mat-icon {
              &.chevron {
                transform: translateY(-50%) rotate(180deg);
              }
            }
          }
          & + .mat-expansion-panel-content {
            position: relative;
            &::before {
              content: '';
              position: absolute;
              left: 8px;
              right: 8px;
              width: calc(100% - 16px);
              height: 1px;
              background: #e2e2e2;
            }
          }
        }
      }
      & > .mat-expansion-panel-content {
        & > .mat-expansion-panel-body {
          padding: 16px 16px 30px;
        }
      }
    }
  }
  &.for-subEvent {
    & > form {
      & > .mat-expansion-panel {
        border-radius: 0 !important;
        border: none;
        & > .mat-expansion-panel-header {
          padding: 20px 15px;
          border-bottom: 1px solid #e2e2e2 !important;
          border-radius: 0 !important;
        }
        & > .mat-expansion-panel-content {
          position: relative;
          &::before {
            display: none !important;
          }
          & > .mat-expansion-panel-body {
            padding: 42px 55px;
          }
        }
        &.mat-expanded {
          // border-bottom: 1px solid #e2e2e2;
          border: 2px solid var(--primary);
          margin-bottom: 20px;
          & > .mat-expansion-panel-header {
            border-bottom-color: var(--primary) !important;
            background: #dae8f8 !important;
          }
        }
      }
    }
    &:first-child{
      .mat-expansion-panel{
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
      }
    }
  }
}

.new-acc-ui {
  padding: 0;
  box-shadow: none !important;
  border-radius: 10px !important;

  .mat-expansion-panel-header {
    border: 1px solid #a6c9ea;
    background: #edf4fb !important;
    height: auto !important;
    padding: 16px 20px;
    .mat-content {
      p {
        color: var(--primary);
      }
    }
    .chevron{
      color: var(--primary);
      margin-right: -6px;
    }
    .mat-expansion-indicator {
      display: none;
    }
    &.mat-expanded {
      background: var(--primary) !important;
      height: auto;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      .mat-content {
        p {
          color: var(--white);
        }
      }
      .chevron{
        color: var(--white);
        transform: rotate(180deg);
      }
    }
  }
  .mat-expansion-panel-content {
    background: #edf4fb !important;
    .mat-expansion-panel-body {
      padding: 25px 20px 22px;
    }
  }
}

.has-badge {
  position: relative;
  .badge-list {
    position: absolute;
    bottom: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    li {
      font-size: 13px;
      border-radius: 20px;
      line-height: 1;
      padding: 7.5px 10px;
      background: rgb(36, 36, 36);
      color: white;
      &.green {
        background: #1db954;
      }
      &.blue {
        background: #0164c1;
      }
      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }
}

.btn-cover {
  margin-left: auto;
  width: 185px;
}
.mat-sort-header-arrow {
  opacity: 0.5 !important;
  transform: none !important;
}
.has-info-text {
  padding: 8px 16px 24px 16px !important;
  min-height: auto !important;
  & + p {
    margin-top: -24px;
    margin-bottom: 6px;
    font-size: 14px;
    padding: 0 16px;
    color: var(--subText);
  }
}
.mat-datepicker-toggle {
  button {
    width: 40px !important;
    height: 40px !important;
    padding: 6px !important;
    margin-left: 3px !important;
  }
}

hr {
  border: none;
  height: 1px;
  background: #d6d6d6;
}
.has-contry-dropdown {
  .mat-mdc-form-field-icon-prefix {
    display: flex;
    padding: 18px;
    margin: -12px 10px -12px -12px;
    border-right: 1px solid #d3d3d3;
  }
  .country-dropdown {
    border: none;
    margin: -10px;
    width: fit-content;
    cursor: pointer;
    img {
      width: 40px;
    }
  }
}

.phone-no-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e2e2e2;
  border-radius: 9px;
  padding-left: 10px;
  position: relative;
  .mdc-notched-outline {
    div {
      border: none;
      border-radius: 0;
    }
  }
  .country-code {
    width: 62px;
    position: relative;
    border-right: 1px solid #d3d3d3;
    .mat-mdc-form-field {
      width: 100%;
      pointer-events: none;
    }
    .mat-mdc-form-field-infix {
      padding: 9px 0;
      height: 44px;
      min-height: 44px;
    }
    .mat-mdc-text-field-wrapper {
      padding-left: 10px;
    }
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
    .mat-mdc-select-arrow-wrapper{
      display: none;
    }
    .country-code-flag {
      img {
        width: 30px;
        border-radius: 4px;
        height: 26px;
        object-fit: cover;
      }
    }
  }

  .input-wrap {
    width: calc(100% - 62px);
    position: relative;
    .mat-mdc-form-field {
      width: 100%;
    }
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
}

.description-detail {
  .ql-editor {
    padding: 0;
  }
  .btn-read-more {
    border: none;
    background: transparent;
    margin-left: auto;
    color: var(--primary);
    font-weight: 500;
  }
}
.ql-editor{
  &::before{
    font-style: inherit !important;
    font-size: "Poppins", san-serif !important;
    font-size: 15px !important;
  }
}


.date-cover,
app-time {
  .mat-mdc-text-field-wrapper {
    position: relative;
    padding-right: 0 !important;
    input {
      position: absolute;
      top: 0;
      height: 100% !important;
    }
  }
}

.organization-details {
  .mat-stepper-horizontal {
    background: transparent;

    .mat-horizontal-stepper-header-container {
      overflow: hidden;
      margin-bottom: 25px;
      margin-top: 12px;
      justify-content: flex-start;
      border-bottom: 5px solid #ececec;
    }

    .mat-step-header {
      height: auto;
      padding: 18px 26px;
      background: transparent;
      border-bottom: 5px solid transparent;
      &:not(:last-child) {
        margin-right: 25px;
      }
      .mat-step-icon {
        display: none;
      }

      .mat-step-text-label {
        font-size: 18px;
        letter-spacing: 0;
        font-family: "Poppins", sans-serif !important;

      }

      &[aria-selected='true'] {
        border-bottom: 5px solid var(--primary);
        color: var(--primary);
      }
    }

    .mat-horizontal-content-container {
      padding: 40px;
      background: var(--white);
      box-shadow: 0px 4px 8px 8px rgba(0, 0, 0, 0.01);
      border-radius: 13px;
    }

    .mat-stepper-horizontal-line {
      // margin: 0 4px;
      display: none;
    }
  }
}

.disabled {
  opacity: 0.7;
}

mat-form-field {
  &.mat-form-field-invalid {
    .mat-mdc-text-field-wrapper {
      background: #ffe6e6 !important;
      .mat-mdc-form-field-flex {
        .mdc-notched-outline {
          div {
            border-color: var(--error) !important;
          }
        }
      }
    }
    input::placeholder,
    .mat-mdc-select-placeholder,
    .mat-mdc-select-value {
      color: var(--error) !important;
    }
  }
}

mat-form-field{
  &.hide-errors{
    .mat-mdc-text-field-wrapper {
      background: #fbfbfb !important;
      .mat-mdc-form-field-flex {
        .mdc-notched-outline {
          div {
            border-color: #e2e2e2 !important;
          }
        }
      }
    }
    input::placeholder,
    .mat-mdc-select-placeholder,
    .mat-mdc-select-value {
      color: #7b7b7b !important;
    }
  }
}

quill-view {
  .ql-editor {
    padding: 0;
  }
}

.char-count {
  color: #8f8996;
  font-size: 14px;
  text-align: right;
  padding-bottom: 0;
}


.page-event-detail {
  .img-cover {
    border-radius: 10px;
    aspect-ratio: 2/1;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
      display: table-cell;
    }
  }
}

mat-slide-toggle {
  .mdc-switch{
    width: 38px !important;
    .mdc-switch__track{
      width: 38px !important;
      height: 20px !important;
      border-radius: 10px !important;
      &::before, &::after{
        background: #ededed !important;
        border: none !important;
      }
    }
    .mdc-switch__handle-track{
      width: 15px !important;
    }
    .mdc-switch__handle{
      width: 15px !important;
      height: 15px !important;
      left: 4px !important;
      &::after{
        background: #c7c7c7 !important;
      }
      .mdc-switch__ripple{
        width: 30px;
        height: 30px;
      }
      .mdc-switch__shadow,
      .mdc-switch__icons{
        display: none;
      }
    }
    &:disabled{
      .mdc-switch__track{
        opacity: 1 !important;
      }
      .mdc-switch__handle{
        &::after{
          opacity: 1 !important;
        }
      }
    }
    &.mdc-switch--checked{
      .mdc-switch__track{
        &::after{
          background: var(--primary) !important;
        }
      }
      .mdc-switch__handle-track{
        .mdc-switch__handle{
          ::after{
            background: var(--white) !important;
          }
        }
      }
      &:disabled{
        .mdc-switch__track{
          opacity: 0.7 !important;
        }
        .mdc-switch__handle{
          &::after{
            opacity: 0.38 !important;
          }
        }
      }
    }
  }
}
.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after{
  background: var(--white) !important;
}
.ql-container {
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  p{
    font-size: 15px;
  }
}

.state-cover {
  font-size: 12px;
  line-height: 2;
  font-weight: 500;
  padding: 6px 16px;
  border-radius: 20px;
  display: inline-block;
  white-space: nowrap;
  color: var(--white);
  &.published {
    background: var(--secondary);
  }

  &.error {
    background: var(--error);
  }
  &.draft {
    background: #0164c1;
  }
}


.page-dashboard {
  .counter-card {
    border-radius: 13px;
    background: var(--white);
    padding: 26px 20px;
    text-align: center;
    height: 100%;
    user-select: none;
    p {
      color: #4a4a4a;
      margin-top: 12px;
      margin-bottom: 5px;
    }
  }
}